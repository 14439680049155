<template>
  <div class="test_group_warp">
    <div class="left">
      <LeftController @initList="initData"
                      ref="subjectRef" />
    </div>
    <div class="right">
      <TopCard ref="TopCardRef"
               text="教师试卷"
               :showBack="false" />
      <div class="list">
        <div class="warp">
          <el-row v-for="(item,index) in tableData"
                  :key="index">
            <el-col :span="10">
              <div class="paper_name">
                <div class="img">
                  <img src="@/assets/choiceness/教师试卷.png"
                       alt="">
                </div>
                <div class="text">
                  <div>
                    {{item.paper_name}}
                  </div>
                  <div>
                    出卷教师:{{item.edu_user_name}} (教师试卷ID:{{item.edu_paper_id}})
                  </div>

                  <div>
                    {{item.paper_heading}}
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :span="5">
              <div class="score">
                {{item.paper_time}}
              </div>
            </el-col>

            <el-col :span="3">
              <div class="score">
                总分：{{item.paper_score}}
              </div>
            </el-col>
            <el-col :span="6">
              <div class="btn_warp">
                <div class="btn"
                     @click="doPaper(item)">
                  开始训练
                </div>
              </div>
            </el-col>
          </el-row>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import LeftController from '@/views/components/left_controller.vue'
import TopCard from '@/views/components/top_card/index.vue'
import { getChoicenessPaper } from '@/api/choiceness.js'
export default {
  data () {
    return {
      tableData: []
    }
  },
  components: {
    LeftController, TopCard
  },
  mounted () {
    this.$removePaperInfo()
  },
  activated () {
    if (!this.$refs.subjectRef.subjectList.length) {
      this.$refs.subjectRef.initData()
    }
  },

  methods: {
    async initData (id) {
      let params = {
        subject_id: id
      }
      const { data } = await getChoicenessPaper(params)
      this.tableData = data.list
    },
    doPaper (item) {
      let params = {
        edu_paper_id: item.edu_paper_id
      }
      this.$router.push('/doPaper?type=4&form=' + JSON.stringify(params))
    }
  }
}
</script>

<style lang="scss" scoped>
.test_group_warp {
  overflow: hidden;
  display: flex;
  .left {
    width: 100rem;
  }
  .right {
    width: calc(100% - 100rem);
    background: #f6f6f6;
    .tabs {
      margin-top: 22rem;
      display: flex;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 18rem;
      .tab {
        cursor: pointer;
        width: 260rem;
        height: 54rem;
        background: #2196f3;
        border-radius: 30rem;
        color: #ffffff;
        font-size: 24rem;
        font-weight: 800;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .list {
      width: 100%;
      max-height: calc(100vh - 60rem - 40rem);
      -ms-overflow-style: none;
      background: white;
      overflow-y: auto;
      overflow: -moz-scrollbars-none;
      &::-webkit-scrollbar {
        width: 0 !important;
      }
      .el-row {
        // height: 140rem;
        height: fit-content;
        padding: 8rem 0;
        border-bottom: 1rem solid #f1f1f1;
        &:hover {
          background: rgba($color: #2196f3, $alpha: 0.1);
        }
      }
      // div {
      //   height: 80rem;
      //   line-height: 80rem;
      // }
      .paper_name {
        margin-left: 50rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        // height: 140rem;
        height: fit-content;
        .img {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 120rem;
          height: 100rem;
          background: #6cb9f5;
          border-radius: 5rem;
          img {
            width: 34rem;
            height: 34rem;
          }
        }
        .text {
          // height: 100rem;
          margin-left: 20rem;
          height: fit-content;
          flex: 1;
          div {
            &:nth-child(1) {
              font-size: 20rem;
              font-weight: bold;
              color: #000000;
            }
            &:nth-child(2) {
              margin-top: 10rem;
              font-size: 20rem;
              font-weight: bold;
              color: #000000;
            }
            &:nth-child(3) {
              margin-top: 10rem;
              font-weight: 500;
              font-size: 20rem;
              color: #333333;
            }
          }
        }
      }
      .score {
        font-weight: 500;
        color: #333333;
        font-size: 20rem;
        height: 110rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
      .btn_warp {
        display: flex;
        height: 110rem;
        align-items: center;
        justify-content: flex-end;
        .btn {
          width: 120rem;
          height: 44rem;
          background: #2196f3;
          margin-right: 20rem;
          border-radius: 5rem;
          font-size: 20rem;
          font-weight: bold;
          color: #ffffff;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
      }
    }
  }
}
</style>